import React from 'react';
import { BibliotecaAudios } from '../components/BibliotecaAudios';
import img from '../img/creando_conciencia.jpg';

const api = process.env.REACT_APP_API_BASE + "/api/v1/podcasts/1";

export const CreandoConciencia = () => {

  return (
    <BibliotecaAudios img={img} api={api}>
      <div className="text-neutral hero-overlay bg-white bg-opacity-10 rounded-3xl p-5">
        <h1 className="mb-5 text-3xl font-bold">Creando Conciencia</h1>
        <p className="mb-5">
          Si estás en la búsqueda de comprender quién en verdad somos y los potenciales que tenemos para crear nuestra realidad, disfrutarás de una vida plena y encontrarás:
        </p>
        <p className="mb-5 font-bold">
          “Motivos” que despiertan tus sentidos.
        </p>
      </div>
    </BibliotecaAudios>
  )
}
