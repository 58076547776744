import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RadioPlayer } from './RadioPlayer';

export const NavbarTop = () => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const location = useLocation()

  React.useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <div className="navbar bg-base-100">
      <div className="md:navbar-start">
        <div className="dropdown">
          <label tabIndex={0} className="btn btn-ghost lg:hidden">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
          </label>
          <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-96">
            <li>
              <Link to='/'>Inicio</Link>
            </li>
            <li>
              <Link to='/noticias'>Noticias</Link>
            </li>
            <li tabIndex={0}>
              <a onClick={handleClick}>
                Entrevistas
                <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
              </a>
              {open && (
              <ul className="p-2 bg-base-100 z-50 relative left-0 flex" onClick={handleClick}>
                <li>
                  <Link to='/alimentacion_consciente'>Alimentación Consciente</Link>
                </li>
                <li>
                  <Link to='/artistas'>Artistas</Link>
                </li>
                <li>
                  <Link to='/conciencia_agroecologica'>Conciencia Agroecológica</Link>
                </li>
                <li>
                  <Link to='/creando_conciencia'>Creando Conciencia</Link>
                </li>
                <li>
                  <Link to='/desarrollo_personal'>Desarrollo Personal</Link>
                </li>
                <li>
                  <Link to='/profesionales'>Profesionales</Link>
                </li>
                <li>
                  <Link to='/talleres_radio'>Taller de Radio y Radioteatro</Link>
                </li>
                <li>
                  <Link to='/terapias_holisticas'>Terapias Holísticas</Link>
                </li>
              </ul>
              )}
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCxJYT9o6V8Z4pwV_n68XA1Q/" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
                <path fill="hsl(var(--p))" d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"></path><path fill="hsl(var(--b1))" d="M20 31L20 17 32 24z"></path>
              </svg> Youtube
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/motivosparacompartir/" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
                <path fill="hsl(var(--p))" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"></path><path fill="hsl(var(--b1))" d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"></path>
              </svg> Motivos para compartir
              </a>
            </li>
          </ul>
        </div>
        <div className="w-full pl-2">
          <h5 className="text-primary text-2xl font-bold p-0">Motivos</h5>
          <label className="label p-0">
            <span className="label-text-alt">que despiertan tus sentidos</span>
          </label>
        </div>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal p-0">
          <li>
            <Link to='/'>Inicio</Link>
          </li>
          <li>
            <Link to='/noticias'>Noticias</Link>
          </li>
          <li>
            <a onClick={handleClick}>
              Entrevistas
              <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
            </a>
            {open && (
            <ul className="p-2 bg-base-100 z-50 flex" onClick={handleClick}>
              <li>
                <Link to='/alimentacion_consciente'>Alimentación Consciente</Link>
              </li>
              <li>
                <Link to='/artistas'>Artistas</Link>
              </li>
              <li>
                <Link to='/conciencia_agroecologica'>Conciencia Agroecológica</Link>
              </li>
              <li>
                <Link to='/creando_conciencia'>Creando Conciencia</Link>
              </li>
              <li>
                <Link to='/desarrollo_personal'>Desarrollo Personal</Link>
              </li>
              <li>
                <Link to='/profesionales'>Profesionales</Link>
              </li>
              <li>
                <Link to='/talleres_radio'>Taller de Radio y Radioteatro</Link>
              </li>
              <li>
                <Link to='/terapias_holisticas'>Terapias Holísticas</Link>
              </li>
            </ul>
            )}
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCxJYT9o6V8Z4pwV_n68XA1Q/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
              <path fill="hsl(var(--p))" d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"></path><path fill="hsl(var(--b1))" d="M20 31L20 17 32 24z"></path>
            </svg> Youtube
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/motivosparacompartir/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
              <path fill="hsl(var(--p))" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"></path><path fill="hsl(var(--b1))" d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"></path>
            </svg> Motivos para compartir
            </a>
          </li>
        </ul>
      </div>
      <div className='navbar-end hidden md:flex'>
        <RadioPlayer device="desktop" />
      </div>
    </div>
  );
}
