import React, { Children } from 'react';
import { AudioPlayer } from './AudioPlayer';

export const BibliotecaAudios = (props) => {
  return (
    <div className='min-h-screen'>
      <div className="hero min-h-[40vh] max-h-[40vh] overflow-hidden rounded-3xl place-items-end content-center pr-0 md:pr-20 hidden md:grid" style={{ backgroundImage: `url("${props.img}")` }}>
        <div className="hero-content text-center text-neutral-content">
          <div className="max-w-lg">
            {props.children}
          </div>
        </div>
      </div>
      <AudioPlayer api={props.api} />
    </div>
  )
}
