import { NavbarTop } from './components/NavbarTop.jsx';
import { Home } from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import { CreandoConciencia } from './pages/CreandoConciencia';
import { DesarrolloPersonal } from './pages/DesarrolloPersonal.jsx';
import { ConcienciaAgroecologica } from './pages/ConcienciaAgroecologica.jsx';
import { AlimentacionConsciente } from './pages/AlimentacionConsciente.jsx';
import { TerapiasHolisticas } from './pages/TerapiasHolisticas.jsx';
import { Profesionales } from './pages/Profesionales.jsx';
import { TalleresRadio } from './pages/TalleresRadio.jsx';
import { Artistas } from './pages/Artistas.jsx';
import { Noticias } from './pages/Noticias.jsx';
import { GoogleAnalytics } from './components/GoogleAnalytics.jsx';
import { RadioPlayer } from './components/RadioPlayer';

function App() {
  return (
    <div className="App container mx-auto font-serif">
      <div className='w-full md:hidden block'>
        <RadioPlayer device="mobile" />
      </div>
      <header>
        <NavbarTop></NavbarTop>
      </header>
      <div className='mx-auto'>
        <GoogleAnalytics/>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/creando_conciencia' element={<CreandoConciencia/>} />
          <Route path='/desarrollo_personal' element={<DesarrolloPersonal/>} />
          <Route path='/conciencia_agroecologica' element={<ConcienciaAgroecologica/>} />
          <Route path='/alimentacion_consciente' element={<AlimentacionConsciente/>} />
          <Route path='/terapias_holisticas' element={<TerapiasHolisticas/>} />
          <Route path='/profesionales' element={<Profesionales/>} />
          <Route path='/talleres_radio' element={<TalleresRadio/>} />
          <Route path='/artistas' element={<Artistas/>} />
          <Route path='/noticias' element={<Noticias/>} />
        </Routes>
      </div>
      <footer className="footer p-7 bg-primary text-base-100 rounded-b-2xl">
        <div className="grid grid-cols-3 w-full">
          <div className="col-span-2">
            <h5 className="text-base-100 text-2xl font-bold p-0">Motivos</h5>
            <p className='flex-wrap'>Copyright © 2022 - Todos los derechos reservados</p>
          </div>
          <div className="justify-self-end grid grid-cols-2 content-center">
            <div>
              <a href="https://www.youtube.com/channel/UCxJYT9o6V8Z4pwV_n68XA1Q/">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
                  <path fill="hsl(var(--b1))" d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"></path><path fill="hsl(var(--p))" d="M20 31L20 17 32 24z"></path>
                </svg>
              </a>
            </div>
            <div>
              <a href="https://www.facebook.com/motivosparacompartir/" target="_blank" rel='noreferrer'>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
                  <path fill="hsl(var(--b1))" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"></path><path fill="hsl(var(--p))" d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
