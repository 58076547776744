import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from "react-router-dom";
const TRACKING_ID = "UA-229736671-3";
ReactGA.initialize(TRACKING_ID);

export const GoogleAnalytics = () => {
  let location = useLocation();
  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);

    //ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);
  return (
    <></>
  )
}
