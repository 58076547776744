import React, { useState } from 'react'
import axios from 'axios';

const api = process.env.REACT_APP_API_BASE + "/api/v1/sponsors";

export const Sponsors = () => {
  const [sponsors, setSponsors] = useState([]);

  React.useEffect(() => {
    axios.get(api).then((response) => {
      setSponsors(response.data);
    });
  }, []);

  return (
    <div className='grid place-content-center'>
      <h1 className='text-white text-3xl font-bold py-5 text-center'>Nuestros sponsors</h1>
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
      {
        sponsors?.map( (sponsor, index) => {
          let link = sponsor.tipo_link === 'whatsapp' ? 'https://api.whatsapp.com/send?phone=' + sponsor.whatsapp :
          sponsor.tipo_link === 'sitio_web' ? sponsor.sitio_web : null;
          return (
            <div key={index} className="card w-100">
              <a href={link} target="_blank" rel="noreferrer">
                <figure><img src={process.env.REACT_APP_API_BASE+'/'+sponsor.imagen} alt={sponsor.titulo} /></figure>
              </a>
            </div>
          );
        })
      }
      </div>
    </div>
  )
}
