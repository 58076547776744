import React, { useState } from 'react';
import axios from 'axios';

const api = process.env.REACT_APP_API_BASE + "/api/v1/agenda";

export const Noticias = () => {
  const [noticias, setNoticias] = useState([]);

  React.useEffect(() => {
    axios.get(api).then((response) => {
      setNoticias(response.data);
    });
  }, []);

  return (
    <div className='noticias bg-white p-5 rounded-t-2xl'>
      <h1 className="mb-5 text-3xl font-bold">Próximo Programa</h1>
      {noticias.map((item, index) => {
        return (
          <div key={index}>
            <div className="divider">{item.fecha}</div>
            <div dangerouslySetInnerHTML={{__html: item.texto}}></div>
          </div>
        );
      })}
    </div>
  )
}
