import React from 'react'

const Feed = (props) => {
  const { id, caption, media_type, media_url} = props.feed
  let post;

  switch (media_type) {
    case "VIDEO":
      post = (
        <video
          className="w-full h-80"
          src={media_url}
          type="video/mp4"
          controls playsInline>
        </video>
      )
      break;
    case "CAROUSEL_ALBUM":
      post = (
        <img
          className="w-full"
          src={media_url}
          alt={caption}
        />
      );
      break;
    default:
      post = (
        <img
          className="w-full h-80"
          src={media_url}
          alt={caption}
        />
      );
  }

  return (
    <div id={id} className="carousel-item relative lg:w-1/6 md:w-1/2 w-full">
      {post}
      {/* <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
        <a href="#slide4" className="btn btn-circle">❮</a>
        <a href="#18189352357214738" className="btn btn-circle">❯</a>
      </div> */}
    </div>
  );
}

export default Feed;
