import React from 'react';
import { BibliotecaAudios } from '../components/BibliotecaAudios';
import img from '../img/terapias_holisticas.jpg';

const api = process.env.REACT_APP_API_BASE + "/api/v1/podcasts/5";

export const TerapiasHolisticas = () => {
  return (
    <BibliotecaAudios img={img} api={api}>
      <div className="text-neutral hero-overlay bg-white bg-opacity-60 rounded-3xl p-5">
        <h1 className="mb-3 text-3xl font-bold">Terapias Holísticas</h1>
        <p className="mb-3">
          Otras alternativas que ayudan a mejorar la calidad de vida.
        </p>
        <p className="mb-3">
          Biodecodificación. Flores de Bach. Reiki. Registros Akáshicos. Tarot.
        </p>
      </div>
    </BibliotecaAudios>
  )
}
