import React from 'react';
import { BibliotecaAudios } from '../components/BibliotecaAudios';
import img from '../img/artistas.jpg';

const api = process.env.REACT_APP_API_BASE + "/api/v1/podcasts/55";

export const Artistas = () => {

  return (
    <BibliotecaAudios img={img} api={api}>
      <div className="text-neutral hero-overlay bg-white bg-opacity-60 rounded-3xl p-5">
        <h1 className="mb-0 text-3xl font-bold">Artistas</h1>
      </div>
    </BibliotecaAudios>
  )
}
