import React from 'react';
import { BibliotecaAudios } from '../components/BibliotecaAudios';
import img from '../img/alimentacion_consciente.jpg';

const api = process.env.REACT_APP_API_BASE + "/api/v1/podcasts/4";

export const AlimentacionConsciente = () => {
  return (
    <BibliotecaAudios img={img} api={api}>
      <div className="text-white hero-overlay bg-opacity-50 rounded-3xl p-5">
        <h1 className="mb-5 text-3xl font-bold">Alimentación Consciente</h1>
        <p className="mb-5">
          Con las Lic. en Nutrición Agustina Misson y Ayelén Crippa
        </p>
        <p className="mb-5">
          Comer es una necesidad fisiológica. Pero ¿cómo comemos? ¿Le damos a nuestro organismo lo que necesita? ¿Somos conscientes de cómo lo nutrimos? La   alimentación consciente también implica el concepto de bioindividualidad, porque todos somos distintos. Por eso es importante llevar un registro de lo que se come y como nos sentimos tanto física como emocionalmente.
        </p>
      </div>
    </BibliotecaAudios>
  )
}
