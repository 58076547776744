import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'

import Feed from './Feed'

const InstagramFeeds = ({tokena, ...props}) => {
    const [feeds, setFeedsData] = useState([])
    //use useRef to store the latest value of the prop without firing the effect
    //const tokenProp = useRef(token);
    //console.log(tokenProp, props)
    //token = tokenProp.current;

    useEffect(() => {
        // this is to avoid memory leaks
        const abortController = new AbortController();

        async function fetchInstagramPost () {
          try{
            axios
                .get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=${props.limit}&access_token=${props.token}`)
                .then((resp) => {
                    setFeedsData(resp.data.data)
                })
          } catch (err) {
              console.log('error', err)
          }
        }

        // manually call the fecth function
        fetchInstagramPost();

        return () => {
            // cancel pending fetch request on component unmount
            abortController.abort();
        };
    }, [props.limit])

    return (
      <React.Fragment>
        <div className="carousel w-full">
          {feeds.map((feed) => (
              <Feed key={feed.id} feed={feed} />
          ))}
        </div>
        <div className='grid place-content-center py-4'>
          <a href='https://www.instagram.com/motivos_radiofenix/' target="_blank">
            <img src="https://img.icons8.com/fluency/48/000000/instagram-new.png" alt='Instagram' className='inline' /> ¡Seguinos en Instagram!
          </a>

        </div>
      </React.Fragment>
    );
}

export default InstagramFeeds;
