import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

export const AudioPlayer = (props) => {
  const audio_ref = useRef(null);

  const [list, setList] = useState([]);
  const [audio_src, setAudioSrc] = useState(null);
  const [audio_title, setAudioTitle] = useState(null);
  const [audio_img, setAudioImg] = useState(null);
  const [audio_fecha, setAudioFecha] = useState(null);
  const [audio_autor, setAudioAutor] = useState(null);
  const [query, setQuery] = useState("");

  const setAudio = (item) => {
    setAudioSrc(item.audio);
    setAudioTitle(item.titulo);
    setAudioImg(process.env.REACT_APP_API_BASE + "/" + item.imagen);
    setAudioFecha(item.fecha);
    setAudioAutor(item.autor);
    audio_ref.current.load();
    return;
  };

  const filtrar = (items) => {
    return items.filter(item => {
      return item.titulo.toLowerCase().includes(query) || item.autor.toLowerCase().includes(query) || item.fecha.toLowerCase().includes(query) ;
    });
  };

  useEffect(() => {
    if(list.length < 1) {
      axios.get(props.api).then((response) => {
        let asa = setList(response.data);
        setAudio(response.data[0]);
      });
    }
  });

  return (
    <React.Fragment>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 md:gap-4 p-1 md:p-5">
        <div className="justify-self-center">
          <div className="card w-96 bg-primary shadow-xl p-3">
            <div className="card-body text-center">
              <figure><img src={audio_img} alt={audio_title} className='min-h-[220px] mask mask-circle' /></figure>
              <kbd className="kbd kbd-sm mb-2">{audio_autor}</kbd>
              <div className="card-actions text-center">
                <audio ref={audio_ref} controls>
                  <source src={audio_src} type="audio/mp3" />
                </audio>
              </div>
              <h2 className="text-base text-white text-center">{audio_title}</h2>
              <div className="badge badge-white badge-outline text-white mx-auto">{audio_fecha}</div>
            </div>
          </div>
        </div>
        <div className="justify-self-start col-span-2 bg-base-200 p-1 md:p-5 w-full rounded-lg mt-3 md:mt-0">
          <input type="text" placeholder='Buscar por título, autor o fecha' className='input input-bordered input-sm w-full mb-5' onChange={(e) => setQuery(e.target.value.toLocaleLowerCase())}></input>
          <ul className='menu menu-compact bg-base-100 p-1 rounded-box'>
            {filtrar(list).map((item, index) => (
              <li key={index} className="py-1 md:py-0">
                <button onClick={(e) => setAudio(item, e)} className='p-1 w-100 h-10'>
                  <div className='flex flex-row flex-wrap md:flex-nowrap w-full'>
                    <div className='text-left basis-[75%] md:basis-[30%] shrink-0 md:shrink self-center'>
                      <img src="https://img.icons8.com/ios-glyphs/25/000000/play-button-circled--v1.png" alt="Reproducir" className='hidden md:inline-flex mr-1' />
                      <div className="md:badge md:badge-primary md:badge-outline text-left">
                        { item.autor }
                      </div>
                    </div>
                    <div className='text-left order-last md:order-none basis-[100%] md:basis-[55%] shrink-0 md:shrink'>{ item.titulo }</div>
                    <div className='text-right md:order-last basis-[25%] md:basis-[15%] shrink-0 md:shrink'>
                      <div className="md:badge md:badge-primary md:badge-outline">{ item.fecha }</div>
                    </div>
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  )
}
