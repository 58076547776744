import React from 'react';

const styles = {
    span: {
        marginRight:'-20px',
        paddingRight:'30px',
        backgroundColor: 'transparent',
    },
    div: {
      desktop: {
        width: '250px',
      },
      mobile: {
        width: '100%',
      }
    },
}

export const RadioPlayer = (props) => {
  return (
    <div className="form-control" style={styles.div[props.device]}>
      <label className="input-group p-1 bg-primary rounded-full">
        <span className='font-bold text-xs text-white whitespace-nowrap animate-pulse' style={styles.span}>EN VIVO</span>
        <audio controls style={styles.audio}>
          <source src="https://streaming01.shockmedia.com.ar/9628/stream" type="audio/ogg" />
          <source src="https://streaming01.shockmedia.com.ar/9628/stream" type="audio/mpeg" />
        </audio>
      </label>
    </div>
  );
}
