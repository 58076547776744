import React from 'react';
import { BibliotecaAudios } from '../components/BibliotecaAudios';
import img from '../img/conciencia_agroecologica.jpg';

const api = process.env.REACT_APP_API_BASE + "/api/v1/podcasts/3";

export const ConcienciaAgroecologica = () => {
  return (
    <BibliotecaAudios img={img} api={api}>
      <div className="text-neutral hero-overlay bg-white bg-opacity-60 rounded-3xl p-5">
        <h1 className="mb-5 text-3xl font-bold">Conciencia Agroecológica</h1>
        <p className="mb-2">
            Por medio de la educación y de la Soberanía alimentaria se pretende avanzar sobre:<br/>
            ● La Calidad de vida. ● La diversidad biológica.<br/>
            ● La conservación de nuestros ecosistemas. ● La salud ambiental.
        </p>
        <p className='mb-2'>
          Una huerta agroecológica es una actividad productiva y, en forma simultánea, es una actividad educativa de amplio impacto social. Ya que se manifiesta sobre el valor de la libertad y de la responsabilidad.
        </p>
        <p className='mb-2'>
          Hablar de agroecología es hablar de la salud del suelo, por ende de la salud del territorio y quienes lo habitamos.
        </p>
      </div>
    </BibliotecaAudios>
  )
}
