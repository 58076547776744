import React from 'react';
import { Hero } from '../components/Hero.jsx';
import { Sponsors } from '../components/Sponsors';
import InstagramFeeds from '../components/InstagramFeeds';

export const Home = () => {

  React.useEffect(() => {
    document.getElementById('my-modal-4').checked = true;
  },[]);

  return (
    <React.Fragment>
      <div>
        <input type="checkbox" id="my-modal-4" className="modal-toggle" />
        <label htmlFor="my-modal-4" className="modal cursor-pointer">
          <label className="modal-box relative" htmlFor="">
            <h3 className="text-3xl font-bold text-center">¡¡¡ NUEVO HORARIO !!!</h3>
            <p className="py-4 text-center">Desde este viernes, te acompañaremos de 17 a 19 Hs.</p>
          </label>
        </label>
      </div>
      <div className='bg-primary rounded-3xl'>
        <Hero />
      </div>
      <div className='bg-base-200 p-5 my-5'>
        <InstagramFeeds token={process.env.REACT_APP_IG_TOKEN} limit={6} />
      </div>
      <div className="bg-base-300 p-5 rounded-3xl my-5">
        <Sponsors />
      </div>
    </React.Fragment>
  )
}
