import React from 'react';
import { BibliotecaAudios } from '../components/BibliotecaAudios';
import img from '../img/desarrollo_personal.jpg';

const api = process.env.REACT_APP_API_BASE + "/api/v1/podcasts/2";

export const DesarrolloPersonal = () => {
  return (
    <BibliotecaAudios img={img} api={api}>
      <div className="text-neutral hero-overlay bg-white bg-opacity-30 rounded-3xl p-5">
        <h1 className="mb-0 text-3xl font-bold">Desarrollo Personal</h1>
        <h3 className="mb-3 text-xl font-bold">Con Graciela Taffarelli</h3>
        <p className="mb-1">
          “El cuidado de la salud emocional, mejora la manera en que nos relacionamos con nosotros mismos, con los otros y con el medio.<br />
          Identificar, el malestar y/o conflicto, es el primer paso para comenzar a resolver en beneficio propio y de nuestro entorno.<br />
          El bienestar es una construcción que se puede alcanzar en un trabajo sobre el propio ser.
          “Sentirse bien”, es un derecho que todos tenemos. Sin embargo exige un compromiso y un trabajo de auto-conocimiento“.
        </p>
        <p className="mb-2 font-bold">
          “Motivos” que despiertan tus sentidos.
        </p>
      </div>
    </BibliotecaAudios>
  )
}
