import React from 'react';
import img from '../img/hero_home3.jpeg';

export const Hero = () => {
  return (
    <div className="hero text-base-100">
      <div className="hero-content flex-col lg:flex-row text-center text-lg">
        <img src={img} className="max-w-xs md:max-w-sm mask mask-heart" alt="Motivos" />
        <div className='p-2 sm:p-5 -mt-8 sm:mt-0'>
          <h1 className="text-5xl font-bold">Motivos</h1>
          <h2 className="text-3xl font-bold">...que despiertan tus sentidos</h2>
          <p className='py-2'>
            Te invita a pasar la tarde con la mejor onda, buena música y temas profundos, para conectarte con tu interior, elegir, evolucionar, y crear con conciencia, esa realidad que anhelás vivir.
          </p>
          <p className='py-2 italic text-lg'>
            “Quien mira hacia afuera, sueña. Quien mira hacia dentro, despierta” <span className="not-italic">- Dr. Carl Gustav Jung</span>
          </p>
          <p className='py-2'>
            Conduce: Silvia Gonçalves
          </p>
          <p className='py-2'>
            Todos los viernes de 17 a 19 Hs.<br /><br />
            MUCHAS GRACIAS POR ESTAR!<br /><br />
            CONSULTÁ NUESTRO BLOG DE NOTICIAS<br />
          </p>
        </div>
      </div>
    </div>
  );
}
